import React, { Component } from "react";
import { Button, Col, Image } from "react-bootstrap";
import { Typography } from "@material-ui/core";

class DescConnectDetail extends Component {
  state = {};
  render() {
    const { title, subtitle, children, platimg } = this.props;
    return (
      <Col md="auto" style={{}}>
        <div
          style={{
            maxWidth: 360,
            margin: "auto",
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "white",
              textAlign: "center",
              fontWeight: "500",
              lineHeight: 1.2,
              marginTop: "3vh",
            }}
            gutterBottom
          >
            {title}
            <Image
              height={18}
              src={platimg}
              style={{ marginTop: -8, marginLeft: 10 }}
            />
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: "rgb(0, 231, 161)",
              textAlign: "center",
              lineHeight: 1.2,
              marginBottom: "2vh",
            }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              width: "100%",
              lineHeight: 1.8,
              color: "white",
              textAlign: "center",
              fontWeight: "200",
              marginBottom: "1vh",
            }}
          >
            {children}
          </Typography>
        </div>
      </Col>
    );
  }
}

export default DescConnectDetail;
