import React, { Component } from "react";
import Screen from "../components/Screen";
import { Typography } from "@material-ui/core";
import { Container, Row, Button } from "react-bootstrap";
import DownloadForPlatform from "../components/DownloadForPlatform";
import Section from "../components/Section";
import DescConnect from "../components/DescConnect";
import iOSimage from "../assets/iOS.png";
import androidimage from "../assets/playstore.png";
import TouchFlowForIos from "../assets/TouchFlowPC/TouchflowPC for iOS.msi";
import TouchFlowForAndroid from "../assets/TouchFlowPC/TouchflowPC for Android.msi";
import { ArrowForwardIos } from "@material-ui/icons";

class Download extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <Screen>
        <Container
          style={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            style={{
              color: "white",
              textAlign: "center",
              fontWeight: "500",
              lineHeight: 1.2,
              marginTop: "5vh",
            }}
            gutterBottom
          >
            {t("download_card1_title1")}
          </Typography>

          <Section title={t("download_card2_title1")} variant="h5">
            <Typography
              variant="subtitle1"
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: "200",
                marginTop: "2vh",
              }}
            >
              {t("download_card2_subtitle1")}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: "200",
              }}
              gutterBottom
            >
              {t("download_card2_subtitle2")}
            </Typography>
            <Row
              className="justify-content-md-center"
              style={{
                marginTop: "3vh",
                paddingTop: 15,
                marginLeft: 15,
                marginRight: 15,
                paddingBottom: 60,
              }}
            >
              <DownloadForPlatform
                mobile_plat={t("download_card2_forandroid_title")}
                pc_plat={"Windows 7 | 10 +"}
                appsrc={androidimage}
                apphref={
                  "https://play.google.com/store/apps/details?id=io.touchflow.app"
                }
                pchref={TouchFlowForAndroid}
              ></DownloadForPlatform>
              <DownloadForPlatform
                mobile_plat={t("download_card2_forios_title")}
                pc_plat={"Windows 10 +"}
                appsrc={iOSimage}
                apphref={"https://apps.apple.com/kr/app/touchflow/id1510129181"}
                pchref={TouchFlowForIos}
              ></DownloadForPlatform>
            </Row>

            <Section title={t("download_card3_title1")} variant="h5">
              <Typography
                variant="subtitle1"
                style={{
                  color: "white",
                  textAlign: "center",
                  fontWeight: "200",
                  marginTop: "2vh",
                }}
              >
                {t("download_card3_subtitle1")}
                <br />
                {t("download_card3_subtitle2")}
              </Typography>
              <Row
                className="justify-content-md-center"
                style={{
                  marginTop: "3vh",
                  paddingTop: 15,
                  paddingBottom: 60,
                  paddingLeft: "3vw",
                  paddingRight: "3vw",
                }}
              >
                <DescConnect
                  title={t("download_card3_guide_forandroid_title")}
                  subtitle={t("download_card3_guide_forandroid_subtitle")}
                >
                  1. {t("download_card3_guide_forandroid_desc1")}
                  <br />
                  2. {t("download_card3_guide_forandroid_desc2")}
                  <br />
                  3. {t("download_card3_guide_forandroid_desc3")}
                </DescConnect>
                <DescConnect
                  title={t("download_card3_guide_forios_title")}
                  subtitle={t("download_card3_guide_forios_subtitle")}
                >
                  1. {t("download_card3_guide_forios_desc1")}
                  <br />
                  2. {t("download_card3_guide_forios_desc2")}
                  <br />
                </DescConnect>
              </Row>
              <Button
                href="/guide"
                variant="outline-light"
                style={{ width: "90%", maxWidth: 400, borderWidth: 1 }}
              >
                {t("download_card3_btn_go_guide")}
              </Button>
            </Section>
          </Section>
        </Container>
      </Screen>
    );
  }
}

export default Download;
