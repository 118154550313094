import React, { Component } from "react";
import Screen from "../components/Screen";

class NotFound extends Component {
  state = {};
  render() {
    return <Screen>NotFound</Screen>;
  }
}

export default NotFound;
