import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Col, Image, Button } from "react-bootstrap";

class ParterProfile extends Component {
  state = {};

  render() {
    const { name, role, children, imgSrc } = this.props;
    return (
      <>
        {imgSrc ? (
          <Col style={{ padding: 0, paddingTop: 50 }}>
            <div style={{ margin: "auto", textAlign: "center" }}>
              <Image
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  margin: "auto",
                  height: 80,
                }}
                src={imgSrc}
              />
            </div>
          </Col>
        ) : (
          <Col>
            <div
              style={{
                width: 230,
                margin: "auto",
                textAlign: "center",
              }}
            ></div>
          </Col>
        )}
      </>
    );
  }
}

export default ParterProfile;
