import React, { Component } from "react";
import { Container, Table, Button } from "react-bootstrap";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div
        className="footer_padding"
        style={{
          backgroundColor: "#111111",
          paddingTop: 20,
        }}
      >
        <Container>
          <Table
            responsive="sm"
            size="sm"
            borderless
            style={{
              borderTop: "1px solid #5f5f5f",
              color: "grey",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan="3"
                  style={{
                    paddingTop: 20,
                    fontSize: 20,
                    height: 30,
                    lineHeight: 1,
                  }}
                >
                  Flowithus Company
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>TouchFlow</td>
                <td colSpan="2" style={{ height: 30 }}>
                  Copyright © 2020 Flowithus All rights reserved.
                  <br />
                  Registration Number : 449-39-00675 | CEO : Jongheyon Ko
                  <br /> Coworking Space, 124, Seogyeong-ro, Seongbuk-gu, Seoul,
                  Republic of Korea
                  <br />
                  Connect User with Tools Flowly
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Button
                    href="/terms"
                    variant="outline-light"
                    size="sm"
                    style={{
                      fontSize: 11,
                      color: "grey",
                      borderColor: "#5f5f5f",
                    }}
                    className="mr-2 mb-1"
                  >
                    Terms of Service
                  </Button>
                  <Button
                    href="/policy"
                    variant="outline-light"
                    size="sm"
                    style={{
                      fontSize: 11,
                      color: "grey",
                      borderColor: "#5f5f5f",
                    }}
                    className="mr-2 mb-1"
                  >
                    Privacy Policy
                  </Button>
                  <Button
                    href="/licenses"
                    variant="outline-light"
                    size="sm"
                    style={{
                      fontSize: 11,
                      color: "grey",
                      borderColor: "#5f5f5f",
                    }}
                    className="mb-1"
                  >
                    Licenses
                  </Button>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}

export default Footer;
