import React from "react";
import { Image } from "react-bootstrap";
import loadinglogo from "../assets/loadinglogo.png";
import { Typography } from "@material-ui/core";
const Loading = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "87vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          id="loadinglogo"
          src={loadinglogo}
          style={{ width: 50, margin: 30 }}
        />
      </div>
      <Typography
        variant="h5"
        style={{
          position: "fixed",
          bottom: 40,
          color: "white",
          textAlign: "center",
          width: "100%",
          fontWeight: "200",
        }}
      >
        Connect user with tools
      </Typography>
    </>
  );
};

export default Loading;
