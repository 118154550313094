import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Col, Image, Button } from "react-bootstrap";

class CommentPerson extends Component {
  state = {};

  render() {
    const { name, role, children } = this.props;
    return (
      <>
        {name ? (
          <Col style={{ padding: 0, paddingTop: 50 }}>
            <div
              style={{
                width: 340,
                margin: "auto",
                textAlign: "center",
              }}
            >
              {name ? (
                <>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: 10,
                      color: "white",
                      textAlign: "center",
                      fontWeight: "bold",
                      letterSpacing: -0.4,
                    }}
                    gutterBottom
                  >
                    {name} ({role})
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "white",
                      textAlign: "center",
                      letterSpacing: -0.4,
                      fontWeight: "light",
                    }}
                  >
                    {'" ' + children + ' "'}
                  </Typography>
                  <Typography
                    className="typoColor-point"
                    variant="h4"
                    style={{
                      color: "white",
                      textAlign: "center",
                      letterSpacing: -0.3,
                    }}
                    gutterBottom
                  ></Typography>
                </>
              ) : null}
            </div>
          </Col>
        ) : (
          <Col>
            <div
              style={{
                width: 230,
                margin: "auto",
                textAlign: "center",
              }}
            ></div>
          </Col>
        )}
      </>
    );
  }
}

export default CommentPerson;
