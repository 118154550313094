import React, { Component } from "react";
import { Typography } from "@material-ui/core";

class Section extends Component {
  render() {
    const {
      title,
      title2,
      subtitle,
      titlebottom,
      title2bottom,
      subtitlebottom,
      variant,
      children,
      textAlign,
    } = this.props;
    return (
      <div
        style={{
          borderTop: "1px solid #2f2f2f",
          paddingTop: "1vh",
          marginBottom: "6vh",
          marginTop: "6vh",
        }}
      >
        {title ? (
          <Typography
            id="shadow_transparent"
            variant={variant}
            style={{
              paddingTop: "5vh",
              color: "white",
              textAlign: textAlign ? textAlign : "center",
              fontWeight: "500",
              wordWrap: "break-word",
              wordBreak: "keep-all",
            }}
          >
            {title}
          </Typography>
        ) : null}
        {title2 ? (
          <Typography
            id="shadow_transparent"
            variant={variant}
            style={{
              color: "white",
              textAlign: textAlign ? textAlign : "center",
              fontWeight: "500",
              wordWrap: "break-word",
              wordBreak: "keep-all",
            }}
          >
            {title2}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography
            id="shadow_transparent"
            id="shadow_transparent"
            variant="h5"
            style={{
              color: "grey",
              textAlign: "center",
              fontWeight: "500",
              lineHeight: 1.6,
              letterSpacing: -0.3,
              maxWidth: 700,
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
              zIndex: 1,
              marginTop: "1vh",
              wordBreak: "keep-all",
            }}
          >
            {subtitle}
          </Typography>
        ) : null}
        {children}
        {titlebottom ? <div style={{ marginTop: "5vh" }}></div> : null}
        {titlebottom ? (
          <Typography
            id="shadow_transparent"
            variant={variant}
            style={{
              color: "white",
              textAlign: textAlign ? textAlign : "center",
              fontWeight: "500",
              wordBreak: "keep-all",
            }}
          >
            {titlebottom}
          </Typography>
        ) : null}
        {title2bottom ? (
          <Typography
            id="shadow_transparent"
            variant={variant}
            style={{
              color: "white",
              textAlign: textAlign ? textAlign : "center",
              fontWeight: "500",
              wordBreak: "keep-all",
            }}
            gutterBottom
          >
            {title2bottom}
          </Typography>
        ) : null}

        {subtitlebottom ? (
          <Typography
            id="shadow_transparent"
            variant="h5"
            style={{
              color: "grey",
              textAlign: "center",
              fontWeight: "500",
              lineHeight: 1.6,
              letterSpacing: -0.3,
              maxWidth: 700,
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
              zIndex: 1,
              wordBreak: "keep-all",
            }}
          >
            {subtitlebottom}
          </Typography>
        ) : null}
      </div>
    );
  }
}

export default Section;
