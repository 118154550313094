import React from "react";
import { Button, Col, Image } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const DownloadForPlatform = ({
  mobile_plat,
  pc_plat,
  apphref,
  pchref,
  appsrc,
}) => {
  const { t } = useTranslation();
  return (
    <Col md="auto">
      <div>
        <Typography
          variant="h5"
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "500",
            lineHeight: 1.2,
            marginTop: "3vh",
          }}
          gutterBottom
        >
          {mobile_plat}
          <Image
            height={20}
            src={appsrc}
            style={{ marginTop: -8, marginLeft: 10 }}
          />
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: "rgb(0, 231, 161)",
            textAlign: "center",
            fontWeight: "200",
            lineHeight: 1.2,
          }}
        >
          {pc_plat}
        </Typography>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "2vh",
          }}
        >
          <Button
            size="lg"
            variant="outline-light"
            href={apphref}
            style={{
              paddingLeft: 60,
              paddingRight: 60,
              borderWidth: 0,
              color: "white",
              backgroundColor: "#1f1f1f",
              marginTop: 10,
            }}
            target="_blank"
            block
          >
            {t("download_card2_app_download")}
          </Button>
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            size="lg"
            variant="outline-light"
            href={pchref}
            style={{
              borderWidth: 0,
              color: "white",
              backgroundColor: "#1f1f1f",
              marginTop: 10,
            }}
            block
          >
            {t("download_card2_pc_download")}
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default DownloadForPlatform;
