import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Col, Image, Button } from "react-bootstrap";

class KeyProgram extends Component {
  state = {};

  render() {
    const { name, role, imgSrc, programicon } = this.props;
    return (
      <>
        {imgSrc ? (
          <Col style={{ padding: 0, paddingTop: 30 }}>
            <div
              style={{
                width: 310,
                margin: "auto",
                textAlign: "center",
              }}
            >
              {imgSrc ? (
                <div style={{ textAlign: "center" }}>
                  <Image
                    className="drop"
                    id="shadow_transparent"
                    width="300vw"
                    src={imgSrc}
                    style={{
                      marginTop: "4vh",
                      maxWidth: 310,
                      paddingRight: 15,
                      paddingLeft: 15,
                      paddingBottom: 15,
                    }}
                  />
                </div>
              ) : null}
              {name ? (
                <>
                  <Typography
                    className="typoColor-point"
                    variant="h5"
                    style={{
                      color: "white",
                      marginTop: 5,
                      textAlign: "center",
                      fontWeight: "bold",
                      letterSpacing: -0.3,
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    {programicon} {name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "rgb(0, 231, 161)",
                      marginTop: 5,
                      textAlign: "center",
                      fontWeight: "bold",
                      letterSpacing: -0.4,
                      fontWeight: "light",
                    }}
                  >
                    {role}
                  </Typography>
                </>
              ) : null}
            </div>
          </Col>
        ) : (
          <Col>
            <div
              style={{
                width: 230,
                margin: "auto",
                textAlign: "center",
              }}
            ></div>
          </Col>
        )}
      </>
    );
  }
}

export default KeyProgram;
