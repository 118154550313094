import React from "react";
import Screen from "../components/Screen";
import { Container } from "react-bootstrap";
import AccordionCustom from "../components/common/AccordionCustom";
import { Typography } from "@material-ui/core";
import Section from "../components/Section";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <Screen>
      <Container>
        <Typography
          variant="h2"
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "500",
            lineHeight: 1.2,
            marginTop: "5vh",
          }}
          gutterBottom
        >
          {t("faq_card1_title1")}
        </Typography>

        <Section title={t("faq_android_title1")} variant="h4">
          <div style={{ marginTop: "2vh" }}></div>
          <AccordionCustom
            heading={t("faq_question_category_bluetooth")}
            secondaryHeading={t("faq_android_question1_title")}
          >
            '페어링'은 블루투스로 연결하기 위해 BLUETOOTH 장치에 정보를 수동으로
            등록하는 데 필요한 절차입니다. BLUETOOTH 연결을 설정하려면 BLUETOOTH
            장치를 페어링해야 합니다. 장치를 끄더라도 페어링 정보가 남아
            있으므로, 동일한 장치를 다시 페어링할 필요가 없습니다.
            <br />
            <br />
            페어링을 통해서 터치플로우 사용자가 원하는 기기를 선택해 안전하게
            연결할 수 있게 도와줄 수 있습니다.
          </AccordionCustom>
          <AccordionCustom
            heading={t("faq_question_category_bluetooth")}
            secondaryHeading="제 컴퓨터에 블루투스가 없는데, 사용할 수 있는 방법이 있나요?"
          >
            기기에 맞는 적합한 블루투스 동글을 구매하셔서 사용하시면, 블루투스
            연결이 가능합니다.
          </AccordionCustom>
          <AccordionCustom
            heading={t("운영체제")}
            secondaryHeading="제 기기의 버전으로 서비스를 이용할 수 있을까요?"
          >
            안드로이드 버전은 최소 21버전(lollipop)부터 사용가능합니다.
            <br />
            windows7 이상부터 가능합니다. 또한 블루투스 연결이 가능해야 합니다.
          </AccordionCustom>

          <Section title={t("faq_ios_title1")} variant="h4">
            <div style={{ marginTop: "2vh" }}></div>
            <AccordionCustom
              heading={t("PC프로그램")}
              secondaryHeading={t("앱을 깔았는데, PC와 연결 할 수 없어요!")}
            >
              1. iPhone과 windowsPC에 각각 앱과 터치플로우 PC(PC프로그램)을
              설치하세요. (다운로드 메뉴에서 설치하실 수 있습니다.)
              <br />
              2. 윈도우 10버전 이상인지 확인하세요.
              <br />
              3. 아이폰이 컴퓨터와 블루투스 페어링을 풀어주셔야 합니다.
              <br />
              4. PC와 아이폰의 블루투스 기능을 모두 활성화 시키세요.
            </AccordionCustom>
            <AccordionCustom
              heading={t("faq_question_category_bluetooth")}
              secondaryHeading={t("faq_ios_question1_title")}
            >
              iPhone을 위한 터치플로우 서비스는 블루투스 4세대를 이용하여 만든
              서비스이므로, 블루투스 페어링이 필요하지 않습니다.
            </AccordionCustom>
            {/* <AccordionCustom
              heading={t("faq_question_category_setting")}
              secondaryHeading="How to Connect with iPhone with PCfkjflsjflkjlkjj"
            >
              kjlkjlk
            </AccordionCustom> */}
            <AccordionCustom
              heading={t("운영체제")}
              secondaryHeading="제 기기의 버전으로 서비스를 이용할 수 있을까요?"
            >
              아이폰 버전은 최소 11버전부터 사용가능합니다.
              <br />
              windows10 이상부터 가능합니다. 또한 블루투스 연결이 가능해야
              합니다.
            </AccordionCustom>
          </Section>
        </Section>
      </Container>
    </Screen>
  );
};

export default FAQ;
