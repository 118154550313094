import React from "react";
import Screen from "../components/Screen";
import { Container, Button, Row } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Section from "../components/Section";
import DescConnectDetail from "../components/DescConnectDetail";
import iOSimage from "../assets/iOS.png";
import androidimage from "../assets/playstore.png";
import AccordionCustom from "../components/common/AccordionCustom";

const ConnectionGuide = () => {
  const { t } = useTranslation();
  return (
    <Screen>
      <Container style={{ textAlign: "center" }}>
        <Typography
          variant="h2"
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "500",
            lineHeight: 1.2,
            marginTop: "5vh",
          }}
          gutterBottom
        >
          {t("connectionguide_card1_title1")}
        </Typography>
        <Section>
          <Typography
            variant="h6"
            style={{
              color: "white",
              textAlign: "center",
              marginTop: "2vh",
            }}
            gutterBottom
          >
            {t("download_card3_subtitle1")}
            <br />
            {t("download_card3_subtitle2")}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: "white",
              textAlign: "center",
              marginTop: "2vh",
            }}
            gutterBottom
          >
            {t("connectionguide_card1_didnt_download_desc")}
          </Typography>
          <Button
            href="/download"
            variant="outline-light"
            style={{ width: "90%", maxWidth: 400, borderWidth: 1 }}
          >
            {t("connectionguide_card1_didnt_download_btn")}
          </Button>
          <Row
            className="justify-content-md-center"
            style={{
              marginTop: "3vh",
              paddingTop: 15,
              paddingBottom: 60,
              paddingLeft: "3vw",
              paddingRight: "3vw",
            }}
          >
            <DescConnectDetail
              title={t("download_card3_guide_forandroid_title")}
              subtitle={t("download_card3_guide_forandroid_subtitle")}
              platimg={androidimage}
            >
              1. {t("download_card3_guide_forandroid_desc1")}
              <br />
              2. {t("download_card3_guide_forandroid_desc2")}
              <br />
              3. {t("download_card3_guide_forandroid_desc3")}
            </DescConnectDetail>
            <DescConnectDetail
              title={t("download_card3_guide_forios_title")}
              subtitle={t("download_card3_guide_forios_subtitle")}
              platimg={iOSimage}
            >
              1. {t("download_card3_guide_forios_desc1")}
              <br />
              2. {t("download_card3_guide_forios_desc2")}
              <br />
            </DescConnectDetail>
          </Row>
        </Section>
      </Container>
      <Container>
        <Typography
          variant="h2"
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "500",
            lineHeight: 1.2,
            marginTop: "5vh",
          }}
          gutterBottom
        >
          {t("faq_card1_title1")}
        </Typography>

        <Section title={t("faq_android_title1")} variant="h4">
          <div style={{ marginTop: "2vh" }}></div>
          <AccordionCustom
            heading={t("faq_question_category_bluetooth")}
            secondaryHeading={t("faq_android_question1_title")}
          >
            {t("faq_android_question1_desc1")}
            <br />
            <br />
            {t("faq_android_question1_desc2")}
          </AccordionCustom>
          <AccordionCustom
            heading={t("faq_question_category_bluetooth")}
            secondaryHeading={t("faq_android_question2_title")}
          >
            {t("faq_android_question2_desc1")}
          </AccordionCustom>
          <AccordionCustom
            heading={t("faq_question_category_operating_system")}
            secondaryHeading={t("faq_android_question3_title")}
          >
            {t("faq_android_question3_desc1")}
            <br />
            {t("faq_android_question3_desc2")}
          </AccordionCustom>

          <Section title={t("faq_ios_title1")} variant="h4">
            <div style={{ marginTop: "2vh" }}></div>

            <AccordionCustom
              heading={t("faq_question_category_bluetooth")}
              secondaryHeading={t("faq_ios_question1_title")}
            >
              {t("faq_ios_question1_desc")}
            </AccordionCustom>
            <AccordionCustom
              heading={t("faq_question_connection_problem")}
              secondaryHeading={t("faq_ios_question2_title")}
            >
              {t("faq_ios_question2_desc1")}
              <br />
              {t("faq_ios_question2_desc2")}
              <br />
              {t("faq_ios_question2_desc3")}
              <br />
              {t("faq_ios_question2_desc4")}
            </AccordionCustom>
            {/* <AccordionCustom
              heading={t("faq_question_category_setting")}
              secondaryHeading="How to Connect with iPhone with PCfkjflsjflkjlkjj"
            >
              kjlkjlk
            </AccordionCustom> */}
            <AccordionCustom
              heading={t("faq_question_category_operating_system")}
              secondaryHeading={t("faq_ios_question3_title")}
            >
              {t("faq_ios_question3_desc1")}
              <br />
              {t("faq_ios_question3_desc2")}
            </AccordionCustom>
          </Section>
        </Section>
      </Container>
    </Screen>
  );
};

export default ConnectionGuide;
