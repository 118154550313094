import React, { Component } from "react";
import { Typography } from "@material-ui/core";

const TextTermsPolicy = ({ title, children }) => {
  return (
    <div style={{ maxWidth: 800, margin: "auto" }}>
      <Typography
        variant="h6"
        style={{
          color: "#dddddd",
          textAlign: "left",
          fontWeight: "500",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          marginLeft: 5,
          color: "grey",
          fontWeight: "500",
          lineHeight: 1.7,
          letterSpacing: -0.6,
          marginTop: "1vh",
          marginBottom: "4vh",
        }}
      >
        {children}
      </Typography>
    </div>
  );
};

export default TextTermsPolicy;
