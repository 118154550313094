import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import { Typography } from "@material-ui/core";

class KeySlideItem extends Component {
  render() {
    const { forwhom, children } = this.props;
    return (
      <div
        style={{
          minHeight: 100,
          maxWidth: 900,
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: 70,
        }}
      >
        <Typography
          variant="h3"
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          {forwhom}
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: "grey",
            textAlign: "center",
            marginTop: "2vh",
            lineHeight: 1.7,
          }}
        >
          {children}
        </Typography>
      </div>
    );
  }
}

export default KeySlideItem;
