import React from 'react'
import Screen from '../components/Screen'
import { Container, Row } from 'react-bootstrap'
import AccordionCustom from '../components/common/AccordionCustom'
import { Typography } from '@material-ui/core'
import Section from '../components/Section'
import PersonProfile from '../components/PersonProfile'
import PartnerProfile from '../components/PartnerProfile'

import jonghyeon from '../assets/person_jonghyeon.png'
import yejin from '../assets/person_yejin.png'
import yiling from '../assets/person_yiling.png'
import sehoon from '../assets/person_sehoon.png'
import taehwan from '../assets/person_taehwan.png'
import juseong from '../assets/person_juseong.png'
import jihoon from '../assets/person_jihoon.png'
import kyeontae from '../assets/person_kyeongtae.png'
import daeseong from '../assets/person_daeseong.png'
import yihsuan from '../assets/person_yihsuan.png'

import logo_ministrystartups from '../assets/logo_ministrystartups.png'
import logo_kisedinstitutestartup from '../assets/logo_kisedinstitutestartup.png'
import { useTranslation } from 'react-i18next'

const Team = () => {
  const { t } = useTranslation()
  return (
    <Screen>
      <Container>
        <Typography
          variant="h2"
          style={{
            color: 'white',
            textAlign: 'center',
            fontWeight: '500',
            lineHeight: 1.2,
            marginTop: '5vh',
            wordBreak: 'keep-all',
          }}
          gutterBottom
        >
          {t('team_card1_title1')}
          <br />
          {t('team_card1_title2')}
        </Typography>

        <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
          <PersonProfile
            name={t('team_card1_jonghyeon_name')}
            role={'Founder & Developer'}
            link={'https://www.linkedin.com/in/jonghyeon-ko-02532b194/'}
            github={'https://github.com/manudeli'}
            imgSrc={jonghyeon}
          >
            {t('team_card1_jonghyeon_history')}
          </PersonProfile>
          <PersonProfile
            name={t('team_card1_yejin_name')}
            role={'Co-Founder & Developer'}
            link={'https://www.linkedin.com/in/yejin-joo-a96025130/'}
            imgSrc={yejin}
          >
            {t('team_card1_yejin_history')}
          </PersonProfile>
          <PersonProfile
            name={t('team_card1_yiling_name')}
            role={'International Marketing'}
            link={'https://www.linkedin.com/in/ylchaoirene8495/'}
            imgSrc={yiling}
          >
            {t('team_card1_yiling_history')}
          </PersonProfile>
          <PersonProfile
            name={t('team_card1_juseong_name')}
            role={'Data Anaylisis'}
            link={'https://linkedin.com/in/주성-김-16b08b1b6'}
            imgSrc={juseong}
          >
            {t('team_card1_juseong_history')}
          </PersonProfile>
          <PersonProfile
            name={t('team_card1_yihsuan_name')}
            role={'International Marketing'}
            imgSrc={yihsuan}
            link={'https://www.linkedin.com/in/yi-hsuan-jenny-lee-16869515a/'}
          >
            {t('team_card1_daeseong_history')}
          </PersonProfile>
          <PersonProfile
            name={t('team_card1_jihoon_name')}
            role={'UX & UI'}
            imgSrc={jihoon}
            email="ijiwork3@gmail.com"
            link={
              'https://www.linkedin.com/in/%EC%A7%80%ED%9B%88-%EC%9D%B4-a4671421b/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
            }
          >
            {t('team_card1_jihoon_history')}
          </PersonProfile>
          <PersonProfile
            name={t('team_card1_sehoon_name')}
            role={'Strategy & Financial'}
            link={'https://www.linkedin.com/in/sehun-kang/'}
            imgSrc={sehoon}
          >
            {t('team_card1_sehoon_history')}
          </PersonProfile>
          {/* <PersonProfile
            name={t("team_card1_kyeontae_name")}
            role={"Front-end Develope"}
            imgSrc={kyeontae}
            github={"https://github.com/kyeongTaeKwon"}
          >
            {t("team_card1_kyeontae_history")}
          </PersonProfile> */}
          {/* <PersonProfile
            name={t("team_card1_daeseong_name")}
            role={"Back-end Develope"}
            imgSrc={daeseong}
            email={"fb1014@naver.com"}
            github={"https://github.com/yskkkkkk"}
          >
            {t("team_card1_daeseong_history")}
          </PersonProfile> */}
          <PersonProfile />
          <PersonProfile />
        </Row>

        <Section title={t('team_card2_title')} variant="h3">
          <Row style={{}}>
            <PartnerProfile imgSrc={logo_ministrystartups} />
            <PartnerProfile imgSrc={logo_kisedinstitutestartup} />
          </Row>
          <PartnerProfile name={''}></PartnerProfile>
        </Section>
      </Container>
    </Screen>
  )
}

export default Team
