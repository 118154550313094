import { Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { Col, Image, Button } from 'react-bootstrap'

class PersonProfile extends Component {
  state = {}

  render() {
    const { name, role, children, imgSrc, link, email, github } = this.props
    return (
      <>
        {imgSrc ? (
          <Col style={{ padding: 0, paddingTop: 50 }}>
            <div
              style={{
                width: 277,
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              {imgSrc ? (
                <Image
                  style={{
                    width: 200,
                    height: 200,
                    filter: 'grayscale(100%)',
                    objectFit: 'cover',
                  }}
                  src={imgSrc}
                  roundedCircle
                />
              ) : null}
              {name ? (
                <>
                  <Typography
                    className="typoColor-point"
                    variant="h5"
                    style={{
                      color: 'white',
                      marginTop: 16,
                      textAlign: 'center',
                      fontWeight: 'bold',
                      letterSpacing: -0.3,
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgb(0, 231, 161)',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      letterSpacing: -0.4,
                      fontWeight: 'light',
                    }}
                  >
                    {role}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: 12,
                      color: 'white',
                      textAlign: 'center',
                      letterSpacing: -0.4,
                      fontWeight: 'light',
                    }}
                  >
                    {/* {children} */}
                  </Typography>
                </>
              ) : null}

              {link ? (
                <Button
                  variant="outline-light"
                  href={link}
                  size="sm"
                  style={{
                    color: 'rgb(0, 231, 161)',
                    borderColor: 'rgb(0, 231, 161)',
                    marginTop: 6,
                    borderRadius: 30,
                  }}
                  target="_blank"
                >
                  Linked in
                </Button>
              ) : null}
              {email ? (
                <Button
                  variant="outline-light"
                  href={'mailto:' + email}
                  size="sm"
                  style={{
                    color: 'rgb(0, 231, 161)',
                    borderColor: 'rgb(0, 231, 161)',
                    marginLeft: 5,
                    marginTop: 6,
                    borderRadius: 30,
                  }}
                  target="_blank"
                >
                  Email
                </Button>
              ) : null}
              {github ? (
                <Button
                  variant="outline-light"
                  href={github}
                  size="sm"
                  style={{
                    color: 'rgb(0, 231, 161)',
                    borderColor: 'rgb(0, 231, 161)',
                    marginLeft: 5,
                    marginTop: 6,
                    borderRadius: 30,
                  }}
                  target="_blank"
                >
                  Github
                </Button>
              ) : null}
            </div>
          </Col>
        ) : (
          <Col>
            <div
              style={{
                width: 230,
                margin: 'auto',
                textAlign: 'center',
              }}
            ></div>
          </Col>
        )}
      </>
    )
  }
}

export default PersonProfile
