import React, { Component, Children } from "react";

class Screen extends Component {
  render() {
    const { children } = this.props;
    return (
      <div
        style={{
          paddingTop: 76,
          backgroundColor: "#111111",
          minHeight: "100vh",
        }}
      >
        {children}
      </div>
    );
  }
}

export default Screen;
