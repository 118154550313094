import React, { Component } from "react";
import Screen from "../components/Screen";
import PartnerProfile from "../components/PartnerProfile";
import { Typography, Hidden } from "@material-ui/core";
import { Image, Container, Row, Col, Button, Carousel } from "react-bootstrap";

import forvideotools from "../assets/forvideotools.png";
import forphototools from "../assets/forphototools.png";
import formotiontools from "../assets/formotiontools.png";
import forvectortools from "../assets/forvectortools.png";
import forpptpdf from "../assets/forpptpdf.png";
import fordocumenttools from "../assets/fordocumenttools.png";
import forvideoviewer from "../assets/forvideoviewer.png";
import phonewithkeyboard from "../assets/phonewithkeyboard.png";
import whatsnext from "../assets/whatsnext.png";
import keyboard from "../assets/keyboard.png";
import keyboard_horizontal from "../assets/horizontal_blured_keyboard.png";
import bluetooth_phone from "../assets/bluetooth_phone.png";
import bluetooth_icon from "../assets/bluetooth.png";
import Section from "../components/Section";
import YoutubeContainer from "../components/YoutubeContainer";
import KeyShortcut from "../components/KeyShorcut";
import KeySlideItem from "../components/KeySlideItem";
import FixedButton from "../components/FixedButton";
import PersonProfile from "../components/PersonProfile";
import CommentPerson from "../components/CommentPerson";
import KeyProgram from "../components/KeyProgram";
import logo_ministrystartups from "../assets/logo_ministrystartups.png";
import logo_kisedinstitutestartup from "../assets/logo_kisedinstitutestartup.png";
import {
  FiberManualRecord,
  InsertDriveFile,
  Movie,
  Photo,
  SettingsRemote,
} from "@material-ui/icons";

class Home extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <Screen>
        <Container>
          <Typography
            id="shadow_transparent"
            variant="h1"
            style={{
              color: "white",
              textAlign: "center",
              fontWeight: "500",
              lineHeight: 1.2,
              letterSpacing: -3,
              marginTop: "4vh",

              wordBreak: "keep-all",
            }}
            gutterBottom
          >
            {t("home_card1_title1")}
            <br />
            {t("home_card1_title2")}
          </Typography>
          <Typography
            id="shadow_transparent"
            variant="h5"
            style={{
              color: "grey",
              textAlign: "center",
              fontWeight: "500",
              lineHeight: 1.6,
              letterSpacing: -0.5,
              marginBottom: "2vh",
              wordBreak: "keep-all",
            }}
          >
            {t("home_card1_subtitle1")}
            <br />
            {t("home_card1_subtitle2")}
          </Typography>
          <div style={{ textAlign: "center" }}>
            <Image
              className="drop"
              id="shadow_transparent"
              width="100%"
              src={forvideotools}
              style={{
                marginTop: "4vh",
                maxWidth: 500,
                paddingRight: 15,
                paddingLeft: 15,
                minHeight: 600,
              }}
            />
          </div>
          <Typography
            id="shadow_transparent"
            variant="h2"
            style={{
              color: "white",
              textAlign: "center",
              fontWeight: "500",
              lineHeight: 1.2,
              letterSpacing: -0.5,
              marginTop: 50,
              position: "relative",
              zIndex: 3,
              wordBreak: "keep-all",
            }}
            gutterBottom
          >
            {t("home_card2_title1")}
            <br />
            {t("home_card2_title2")}
          </Typography>
          <Typography
            id="shadow_transparent"
            variant="h5"
            style={{
              color: "grey",
              textAlign: "center",
              fontWeight: "500",
              lineHeight: 1.6,
              letterSpacing: -0.3,
              maxWidth: 530,
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
              zIndex: 1,
              wordBreak: "keep-all",
            }}
          >
            {t("home_card2_subtitle1")}
          </Typography>
        </Container>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "hidden",
            marginTop: "-50vmin",
            marginBottom: "-15vmin",
          }}
        >
          <Container id="shadow_transparent" style={{ maxWidth: 900 }}>
            <div style={{ textAlign: "right" }}>
              <Image
                onScroll
                src={keyboard}
                style={{
                  position: "relative",
                  zIndex: 1,
                  marginRight: "-70%",
                  width: "130%",
                }}
              />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: 2,
                height: "0",
                color: "white",
                left: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              <FiberManualRecord className="popOnDisplay1" />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: 2,
                height: "0",
                color: "white",
                left: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              <FiberManualRecord className="popOnDisplay2" />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: 2,
                height: "0",
                color: "white",
                left: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              <FiberManualRecord className="popOnDisplay3" />
            </div>
            <div style={{ marginBottom: "-40%" }}></div>
            <Image
              src={phonewithkeyboard}
              style={{
                position: "relative",
                zIndex: 1,
                marginLeft: "-15%",
                width: "100%",
                paddingBottom: "10%",
              }}
            />
          </Container>
        </div>
        <Container>
          <Typography
            id="shadow_transparent"
            variant="subtitle1"
            style={{
              color: "#00e7a1",
              textAlign: "center",
              marginTop: "10vmin",
              wordBreak: "keep-all",
            }}
          >
            {t("home_card3_uptitle")}
          </Typography>
          <Carousel id="shadow_transparent">
            <Carousel.Item>
              <KeySlideItem forwhom={t("Photo Editor")}>
                <KeyShortcut name="Move" corekey="V" />
                <KeyShortcut name="Rectangular Marquee" corekey="M" />
                <KeyShortcut name="Lasso" corekey="L" />
                <KeyShortcut name="Magic Wand" corekey="W" />
                <KeyShortcut name="Crop" corekey="C" />
                <KeyShortcut name="Eyedropper" corekey="I" />
                <KeyShortcut name="Spot Healing Brush" corekey="J" />
                <KeyShortcut name="Brush" corekey="B" />
                <KeyShortcut name="Clone Stamp" corekey="S" />
                <KeyShortcut name="History Brush" corekey="Y" />
                <KeyShortcut name="Eraser" corekey="E" />
                <KeyShortcut name="Gradient" corekey="G" />
                <KeyShortcut name="Dodge" corekey="O" />
                <KeyShortcut name="Pen" corekey="P" />
                <KeyShortcut name="Horizontal Type" corekey="T" />
                <KeyShortcut name="Path Selection" corekey="A" />
                <KeyShortcut name="Rectangle" corekey="U" />
                <KeyShortcut name="Hand" corekey="H" />
                <KeyShortcut name="Zoom" corekey="Z" />
                <KeyShortcut name="Open the Curves" ctrl corekey="M" />
                <KeyShortcut name="Open the Levels" ctrl corekey="L" />
                <KeyShortcut
                  name="Open the Color / Saturation"
                  ctrl
                  corekey="U"
                />
                <KeyShortcut name="Open the Color Balance" ctrl corekey="B" />
                <KeyShortcut name="Show Ruler" ctrl corekey="R" />
                <KeyShortcut name="Show Guide" ctrl corekey=";" />
                <KeyShortcut name="Lock Guide" ctrl alt corekey=";" />
                <KeyShortcut name="Show Grid" ctrl corekey="'" />
                <KeyShortcut name="Undo" ctrl alt corekey="Z" />
                <KeyShortcut name="Redo" ctrl shift corekey="Z" />
                <KeyShortcut name="Zoom in" ctrl corekey="+" />
                <KeyShortcut name="Zoom in" ctrl corekey="-" />
              </KeySlideItem>
            </Carousel.Item>

            <Carousel.Item>
              <KeySlideItem forwhom={t("Vector Editor")}>
                <KeyShortcut name="Selection" corekey="V" />
                <KeyShortcut name="Direct Selection" corekey="A" />
                <KeyShortcut name="Pen" corekey="P" />
                <KeyShortcut name="Blob Brush" shift corekey="B" />
                <KeyShortcut name="Add Anchor Point" corekey="+" />
                <KeyShortcut name="Delete Anchor Point" corekey="-" />
                <KeyShortcut name="Switch to Anchor Point" shift corekey="C" />
                <KeyShortcut name="Type" corekey="T" />
                <KeyShortcut name="Rectangle" corekey="M" />
                <KeyShortcut name="Ellipse" corekey="L" />
                <KeyShortcut name="Pencil" corekey="N" />
                <KeyShortcut name="Eraser" shift corekey="E" />
                <KeyShortcut name="Hand" corekey="H" />
                <KeyShortcut name="Zoom" corekey="Z" />
                <KeyShortcut name="Line Segment" corekey="\" />
                <KeyShortcut name="Snap to Point" alt ctrl corekey="'" />
                <KeyShortcut name="Snap to Grid" shift ctrl corekey="'" />
                <KeyShortcut name="Show Ruler" ctrl corekey="R" />
                <KeyShortcut name="Show Grid" ctrl corekey="'" />
                <KeyShortcut name="Lock Guides" alt ctrl corekey=";" />
                <KeyShortcut name="Show Guides" ctrl corekey=";" />
                <KeyShortcut name="Undo" ctrl corekey="Z" />
                <KeyShortcut name="Redo" ctrl shift corekey="Z" />
                <KeyShortcut name="Zoom in" ctrl corekey="+" />
                <KeyShortcut name="Zoom out" ctrl corekey="-" />
              </KeySlideItem>
            </Carousel.Item>

            <Carousel.Item>
              <KeySlideItem forwhom={t("Video Editor")}>
                <KeyShortcut name="Shuttle Left" corekey="J" />
                <KeyShortcut name="Stop" corekey="K" />
                <KeyShortcut name="Shuttle Right" corekey="L" />
                <KeyShortcut name="Play / Stop" corekey="Spacebar" />
                <KeyShortcut name="Cut" ctrl shift corekey="K" />
                <KeyShortcut name="Selection Tool" corekey="V" />
                <KeyShortcut name="Razor Tool" corekey="C" />
                <KeyShortcut name="Ripple Edit Tool" corekey="B" />
                <KeyShortcut name="Ripple Trim Previous" corekey="Q" />
                <KeyShortcut name="Ripple Trim Next" corekey="W" />
                <KeyShortcut name="Ripple Delete" shift corekey="Del" />
                <KeyShortcut name="Undo" ctrl corekey="Z" />
                <KeyShortcut name="Redo" ctrl shift corekey="Z" />
                <KeyShortcut name="Add Marker" corekey="M" />
                <KeyShortcut name="Go to Next Marker" shift corekey="M" />
                <KeyShortcut
                  name="Go to Previous Marker"
                  ctrl
                  shift
                  corekey="M"
                />
                <KeyShortcut name="Slip Tool" corekey="Y" />
                <KeyShortcut name="Track Select Forward Tool" corekey="A" />
              </KeySlideItem>
            </Carousel.Item>

            <Carousel.Item>
              <KeySlideItem forwhom={t("Motion Editor")}>
                <KeyShortcut
                  name="Show only property Anchor/Position/Rotation/Scale/Opacity "
                  corekey="A"
                />
                <KeyShortcut corekey="P" />
                <KeyShortcut corekey="R" />
                <KeyShortcut corekey="S" />
                <KeyShortcut corekey="T" />
                <KeyShortcut
                  name="Show properties with keyframes"
                  corekey="U"
                />
                <KeyShortcut name="Show All properties" corekey="UU" />
                <KeyShortcut name="Keyframe Graph" shift corekey="F3" />
                <KeyShortcut name="Play / Pause" corekey="Spacebar" />
                <KeyShortcut name="Setting" ctrl corekey="K" />
                <KeyShortcut name="Full Screen" shift corekey="/" />
                <KeyShortcut name="Full Resolution" ctrl corekey="J" />
                <KeyShortcut name="Grid" ctrl corekey="'" />
                <KeyShortcut name="Cut Working Space Left/Right" corekey="B" />
                <KeyShortcut corekey="N" />
                <KeyShortcut name="Move by Keyframe Left/Right" corekey="J" />
                <KeyShortcut corekey="K" />
                <KeyShortcut
                  name="Clip Down/Up"
                  ctrl
                  alt
                  corekey="Arrow Down"
                />
                <KeyShortcut ctrl alt corekey="Arrow Up" />
                <KeyShortcut name="Cut Clip" ctrl shift corekey="D" />
                <KeyShortcut name="Cut Clip Left/Right" alt corekey="[" />
                <KeyShortcut alt corekey="]" />
                <KeyShortcut name="Delete" corekey="Backspace" />
                <KeyShortcut name="Undo/Redo" ctrl corekey="Z" />
                <KeyShortcut ctrl shift corekey="Z" />
              </KeySlideItem>
            </Carousel.Item>

            <Carousel.Item>
              <KeySlideItem forwhom={t("Document Editor")}>
                <KeyShortcut name="TextAlign Wide" ctrl shift corekey="M" />
                <KeyShortcut
                  name="TextAlign Left/Center/Right"
                  ctrl
                  shift
                  corekey="L"
                />
                <KeyShortcut ctrl shift corekey="C" />
                <KeyShortcut ctrl shift corekey="R" />
                <KeyShortcut name="Paragraph Property" alt corekey="T" />
                <KeyShortcut
                  name="Line Spacing Wide/Narrow"
                  shift
                  alt
                  corekey="Z"
                />
                <KeyShortcut shift alt corekey="A" />
                <KeyShortcut
                  name="Margin Left Narrow/Wide"
                  ctrl
                  alt
                  corekey="F5"
                />
                <KeyShortcut ctrl alt corekey="F6" />
                <KeyShortcut
                  name="Margin First Line Narrow/Wide"
                  ctrl
                  corekey="F5"
                />
                <KeyShortcut ctrl corekey="F6" />
                <KeyShortcut name="Text Property" alt corekey="L" />
                <KeyShortcut name="Bold" alt shift corekey="B" />
                <KeyShortcut name="Italic" alt shift corekey="I" />
                <KeyShortcut name="Underline" alt shift corekey="U" />
                <KeyShortcut name="Text Size Up/Down" alt shift corekey="E" />
                <KeyShortcut alt shift corekey="R" />
                <KeyShortcut
                  name="Letter Spacing Up/Down"
                  alt
                  shift
                  corekey="W"
                />
                <KeyShortcut alt shift corekey="N" />
                <KeyShortcut
                  name="Stretch Horizontal Up"
                  alt
                  shift
                  corekey="K"
                />
                <KeyShortcut
                  name="Stretch Horizontal Down"
                  alt
                  shift
                  corekey="J"
                />
                <KeyShortcut name="Undo" ctrl corekey="Z" />
                <KeyShortcut name="Redo" ctrl shift corekey="Z" />
                <KeyShortcut name="Zoom in/out" shift corekey="+" />
                <KeyShortcut shift corekey="-" />
              </KeySlideItem>
            </Carousel.Item>

            <Carousel.Item>
              <KeySlideItem forwhom={t("Slide Presenter")}>
                <KeyShortcut name="White Background" corekey="W" />
                <KeyShortcut name="Black Background" corekey="B" />
                <KeyShortcut name="Volume Mute" corekey="Windows Volume Mute" />
                <KeyShortcut name="Volume Up" corekey="Windows Volume Up" />
                <KeyShortcut name="Volume Down" corekey="Windows Volume Down" />
                <KeyShortcut name="Next Slide" corekey="Arrow Right" />
                <KeyShortcut name="Previous Slide" corekey="Arrow Left" />
                <KeyShortcut name="Previous Slide" corekey="Arrow right" />
                <KeyShortcut
                  name="Presentation from First Slide"
                  corekey="F5"
                />
                <KeyShortcut
                  name="Presentation from Current Slide"
                  shift
                  corekey="F5"
                />
              </KeySlideItem>
            </Carousel.Item>

            <Carousel.Item>
              <KeySlideItem forwhom={t("Youtube Viewer")}>
                <KeyShortcut name="Toggle play / pause" corekey="K" />
                <KeyShortcut name="Rewind 10 seconds" corekey="J" />
                <KeyShortcut name="Fast forward 10 seconds" corekey="L" />
                <KeyShortcut name="Previous video" shift corekey="P" />
                <KeyShortcut name="Next video" shift corekey="N" />
                <KeyShortcut name="Toggle full screen" corekey="F" />
                <KeyShortcut name="Toggle mute" corekey="M" />
                <KeyShortcut name="Sound volume up" corekey="Arrow up" />
                <KeyShortcut name="Sound volume down" corekey="Arrow down" />
                <KeyShortcut
                  name="If the video supports captions, toggle captions ON/OFF"
                  corekey="C"
                />
                <KeyShortcut
                  name="Rotate through font sizes (increasing)"
                  corekey="+"
                />
                <KeyShortcut
                  name="Rotate through font sizes (decreasing)"
                  corekey="-"
                />
              </KeySlideItem>
            </Carousel.Item>
          </Carousel>

          <Section
            title={t("home_card3_title1")}
            title2={t("home_card3_title2")}
            subtitle={t("home_card3_subtitle1")}
            variant="h2"
          >
            <div style={{ marginTop: "3vh" }} />
            <YoutubeContainer src="https://www.youtube.com/embed/dIkao0O3PF0" />
          </Section>

          <Section
            title={t("home_card4_title1")}
            subtitle={t("home_card4_subtitle1")}
            variant="h2"
          >
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
              <KeyProgram
                programicon={<Movie />}
                name={t("keyprogram_videoeditor_name")}
                role={t("keyprogram_videoeditor_forwhom")}
                imgSrc={forvideotools}
              />
              <KeyProgram
                programicon={<Photo />}
                name={t("keyprogram_photoeditor_name")}
                role={t("keyprogram_photoeditor_forwhom")}
                imgSrc={forphototools}
              />
              <KeyProgram
                programicon={<Photo />}
                name={t("keyprogram_vectoreditor_name")}
                role={t("keyprogram_vectoreditor_forwhom")}
                imgSrc={forvectortools}
              />
              <KeyProgram
                programicon={<InsertDriveFile />}
                name={t("keyprogram_documenteditor_name")}
                role={t("keyprogram_documenteditor_forwhom")}
                imgSrc={fordocumenttools}
              />
              <KeyProgram
                programicon={<Movie />}
                name={t("keyprogram_motioneditor_name")}
                role={t("keyprogram_motioneditor_forwhom")}
                imgSrc={formotiontools}
              />
              <KeyProgram
                programicon={<SettingsRemote />}
                name={t("keyprogram_slidepresenter_name")}
                role={t("keyprogram_slidepresenter_forwhom")}
                imgSrc={forpptpdf}
              />
              <KeyProgram
                programicon={<SettingsRemote />}
                name={t("keyprogram_remotecontrol_name")}
                role={t("keyprogram_remotecontrol_forwhom")}
                imgSrc={forvideoviewer}
              />
              <KeyProgram />
              <KeyProgram />
            </Row>
          </Section>
        </Container>
        <Section
          title={t("home_card5_title1")}
          subtitle={t("home_card5_subtitle1")}
          variant="h2"
        >
          <div style={{ marginTop: "6vh" }}></div>
          <div style={{ overflow: "hidden" }}>
            <Container>
              <span style={{ height: "100%", position: "relative" }}>
                <Image
                  style={{ width: "40%", maxWidth: 300 }}
                  id="shadow_transparent"
                  onScroll
                  src={bluetooth_phone}
                ></Image>
                <Image
                  className="bluetoothMotion"
                  style={{
                    marginTop: "-8vmin",
                    top: "50%",
                    left: "40%",
                    width: "24%",
                    color: "white",
                    zIndex: "1",
                    position: "absolute",
                    textAlign: "center",
                  }}
                  id="shadow_transparent"
                  onScroll
                  src={bluetooth_icon}
                ></Image>
              </span>
              <span style={{ margin: 5 }} />
              <Image
                id="shadow_transparent"
                onScroll
                src={keyboard_horizontal}
                style={{
                  marginRight: "-150%",
                  width: "160%",
                  maxWidth: 1350,
                }}
              />
            </Container>
          </div>
        </Section>
        <Container>
          <Section title={t("home_card6_title1")} variant="h2">
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
              <CommentPerson
                name={t("home_card6_person1_name")}
                role={t("home_card6_person1_role")}
              >
                {t("home_card6_person1_desc")}
              </CommentPerson>
              <CommentPerson
                name={t("home_card6_person2_name")}
                role={t("home_card6_person2_role")}
              >
                {t("home_card6_person2_desc")}
              </CommentPerson>
              <CommentPerson
                name={t("home_card6_person3_name")}
                role={t("home_card6_person3_role")}
              >
                {t("home_card6_person3_desc")}
              </CommentPerson>
              <CommentPerson />
            </Row>
          </Section>
          <Section title={t("team_card2_title")} variant="h3">
            <Row style={{}}>
              <PartnerProfile imgSrc={logo_ministrystartups} />
              <PartnerProfile imgSrc={logo_kisedinstitutestartup} />
            </Row>
            <PartnerProfile name={""}></PartnerProfile>
          </Section>

          <FixedButton />
        </Container>
      </Screen>
    );
  }
}

export default Home;
