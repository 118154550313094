import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ExpandMoreSharp } from "@material-ui/icons";

const FixedButton = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        opacity: 0.95,
        position: "relative",
        zIndex: 999,
      }}
    >
      <Button
        className="btn_fixed_bottom"
        href="https://onelink.to/touchflow"
        variant="dark"
        style={{
          borderWidth: 0,
          boxShadow: "0px 0px 20px #0f0f0f",
          position: "fixed",
          borderRadius: 0,
          transition: "0.35s",
          padding: 17,
          zIndex: 999,
        }}
      >
        {t("title_FixedButton")}
      </Button>
      <div
        className="arrow_fixed_bottom"
        style={{
          color: "grey",
          fontWeight: 30,
          position: "fixed",
          zIndex: 999,
          left: 0,
          right: 0,
          textAlign: "center",
        }}
      >
        <ExpandMoreSharp />
      </div>
    </div>
  );
};

export default FixedButton;
