import React, { Component } from "react";

class YoutubeContainer extends Component {
  render() {
    const { src } = this.props;
    return (
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%",
          paddingTop: 30,
          height: 0,
          overflow: "hidden",
        }}
      >
        <iframe
          width={560}
          height={315}
          frameborder={0}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={src}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    );
  }
}

export default YoutubeContainer;
