import React from "react";

const KeyShortcut = ({ name, ctrl, alt, shift, corekey }) => {
  return (
    <>
      {name}
      {name ? ":" : null}{" "}
      {corekey ? (
        <>
          <kbd>
            {ctrl ? (
              <>
                <kbd>ctrl</kbd>+{""}
              </>
            ) : null}
            {alt ? (
              <>
                <kbd>alt</kbd>+{""}
              </>
            ) : null}
            {shift ? (
              <>
                <kbd>shift</kbd>+{""}
              </>
            ) : null}
            <kbd>{corekey}</kbd>
          </kbd>
          {/* <br /> */}{" "}
        </>
      ) : null}
    </>
  );
};

export default KeyShortcut;
