import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import NavBar from "./components/NavBar";
import Home from "./screens/Home";
import NotFound from "./screens/NotFound";
import Download from "./screens/Download";
import ConnectionGuide from "./screens/ConnectionGuide";
import FAQ from "./screens/FAQ";
import Policy from "./screens/Policy";
import Terms from "./screens/Terms";
import Licenses from "./screens/Licenses";
import Footer from "./components/Footer";
import Team from "./screens/Team";

import { withTranslation } from "react-i18next";

import "./App.css";

function App() {
  const HomeTransLated = withTranslation()(Home);
  const DownloadTransLated = withTranslation()(Download);

  return (
    <React.Fragment>
      <NavBar />
      <Switch>
        <Route path="/" exact component={HomeTransLated} />
        <Route path="/download" exact component={DownloadTransLated}></Route>
        <Route path="/guide" exact component={ConnectionGuide}></Route>
        <Route path="/faq" exact component={FAQ}></Route>
        <Route path="/team" exact component={Team}></Route>
        <Route path="/policy" exact component={Policy}></Route>
        <Route path="/terms" exact component={Terms}></Route>
        <Route path="/licenses" exact component={Licenses}></Route>
        <Route path="/not-found" component={NotFound}></Route>
        <Redirect from="/home" exact to="/" />
        <Redirect to="/not-found" />
      </Switch>
      <Footer />
    </React.Fragment>
  );
}

export default App;
