import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { ExpandMoreSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "white",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: "grey",
  },
}));

const AccordionCustom = ({
  panelnumber,
  heading,
  secondaryHeading,
  children,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        style={{ backgroundColor: "#1f1f1f" }}
        expanded={expanded === panelnumber}
        onChange={handleChange(panelnumber)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreSharp style={{ color: "grey" }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>{heading}</Typography>
          <Typography className={classes.secondaryHeading}>
            {secondaryHeading}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ color: "grey" }}>{children}</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AccordionCustom;
