import React from "react";
import { NavDropdown, Navbar, Nav, Container, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo.png";
import NavLinkSimpleSnackbar from "./NavLinkSimpleSnackbar";

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        style={{
          backgroundColor: "#171717",
          borderBottom: "1px solid #5f5f5f",
          boxShadow: "0px 0px 20px #0f0f0f",
        }}
        expand="lg"
        variant="dark"
        fixed="top"
      >
        <Container>
          <Navbar.Brand
            href="/"
            style={{
              fontWeight: "bold",
              fontSize: 0,
              paddingTop: 0,
              paddingBottom: 0,
              marginRight: 35,
              opacity: 0,
            }}
          >
            D
          </Navbar.Brand>
          <Navbar.Brand
            href="/"
            style={{ fontSize: 13, letterSpacing: -0.5, fontWeight: "bold" }}
          >
            <Image src={logo} height={20} />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ fontSize: 13, border: 0, padding: 0 }}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/">{t("Home")}</Nav.Link>
              <Nav.Link href="/download">{t("Download")}</Nav.Link>
              <Nav.Link href="/guide">{t("Connection Guide")}</Nav.Link>
              {/* <Nav.Link href="/faq">{t("FAQ")}</Nav.Link> */}
              <Nav.Link href="/team">{t("Team")}</Nav.Link>
              <NavLinkSimpleSnackbar></NavLinkSimpleSnackbar>

              <NavDropdown id="collasible-nav-dropdown" title={t("Language")}>
                <div
                  style={{
                    backgroundColor: "#1f1f1f",
                    paddingTop: 12,
                  }}
                ></div>
                <NavDropdown.Item
                  href="#"
                  onClick={() => handleClick("en")}
                  style={{ paddingTop: 6, paddingBottom: 6 }}
                >
                  English
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => handleClick("zh")}
                  style={{ paddingTop: 6, paddingBottom: 6 }}
                >
                  中文(简体)
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => handleClick("zh-TW")}
                  style={{ paddingTop: 6, paddingBottom: 6 }}
                >
                  中文(繁體)
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => handleClick("ko")}
                  style={{ paddingTop: 6, paddingBottom: 6 }}
                >
                  한국어
                </NavDropdown.Item>
                <div
                  style={{
                    backgroundColor: "#1f1f1f",
                    paddingTop: 12,
                  }}
                ></div>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
